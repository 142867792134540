import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { store_data, client_url } from '../data/functions';
import NavBarInactiveStore from '../components/NavBarInactiveStore';

const InactiveStore = () => {
    const navigate = useNavigate();
    const homePage = () => {
        setTimeout(() => {
            navigate('/')
        }, 1000);
        return
    }
    return (
        <>
            <NavBarInactiveStore></NavBarInactiveStore>
            <div className='inactive-page'>

                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    borderBottom: '1px solid #eaeaea;',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100vh',
                    paddingTop: '30px'
                }}>
                    <div style={{
                        border: '1px solid #e7e7e7',
                        backdropFilter: 'blur(10px)',
                        webkitBackdropFilter: 'blur(10px)',
                        borderRadius: '10px',
                        marginBottom: '20px',
                        padding: '30px',
                        textAlign: 'center',
                        margin: '10px'
                    }}>
                        <center><h3>Sorry store not available</h3></center>
                        <div style={{
                            borderBottom: '1px solid #eaeaea',
                            marginBottom: '15px'
                        }}>
                        </div>
                        <br />
                        <Button className='btn-hairpro' onClick={() => homePage()}>Go to home page</Button>
                    </div>
                </div>
            </div>
        </>

    );
};
export default InactiveStore; 