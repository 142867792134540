// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containt-businesshour {
  padding: 0 10px 0 10px;
  margin-bottom: 20px;
}

.our-teams .swiper-slide {
  width: 100px;
}

.our-teams .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.our-teams .swiper-slide img {
  border-radius: 50%;
  border: 5px inset #b9a036;
  transition: all 0.8s;
}

.our-teams .swiper-slide img:active,
.our-teams .swiper-slide img:hover,
.our-teams .swiper-slide img.active {
  border-radius: 50%;
  border: 5px groove #e604e6;
  transition: all 0.8s;
}

.our-teams .swiper-slide #team-name {
  background-color: #b9a036;
  padding: 5px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-size: 0.8rem;
}

.our-teams .swiper-slide .item-name-skeleton {
  font-weight: bold;
  width: 50px;
}

.our-teams img {
  border-radius: 50%;
  border: 5px solid #b9a036;
}

.containt-businesshour .containt-businesshour,
.containt-businesshour .containt-team,
.containt-team {
  border: 1px solid #e7e7e7;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 10px;
  margin-bottom: 20px;
  /* animation-name: fadeLeft;
  animation-duration: 1s;
  animation-fill-mode: both; */
}

/* @keyframes fadeLeft {
    0% {
        transform: translateX(-900px);
    }

    100% {
        transform: translateX(0);
    }
} */`, "",{"version":3,"sources":["webpack://./src/styles/components/_businesshour.css"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,yBAAA;EACA,oBAAA;AACJ;;AAEA;;;EAGI,kBAAA;EACA,0BAAA;EACA,oBAAA;AACJ;;AAEA;EACI,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,WAAA;AACJ;;AAEA;EACI,kBAAA;EACA,yBAAA;AACJ;;AAEA;;;EAGI,yBAAA;EACA,mCAAA;UAAA,2BAAA;EACA,mBAAA;EACA,mBAAA;EACA;;8BAAA;AAGJ;;AAEA;;;;;;;;GAAA","sourcesContent":[".containt-businesshour {\n    padding: 0 10px 0 10px;\n    margin-bottom: 20px;\n}\n\n.our-teams .swiper-slide {\n    width: 100px;\n}\n\n.our-teams .item {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.our-teams .swiper-slide img {\n    border-radius: 50%;\n    border: 5px inset #b9a036;\n    transition: all 0.8s;\n}\n\n.our-teams .swiper-slide img:active,\n.our-teams .swiper-slide img:hover,\n.our-teams .swiper-slide img.active {\n    border-radius: 50%;\n    border: 5px groove #e604e6;\n    transition: all .8s;\n}\n\n.our-teams .swiper-slide #team-name {\n    background-color: #b9a036;\n    padding: 5px;\n    border-radius: 10px;\n    color: white;\n    font-weight: bold;\n    font-size: 0.8rem;\n}\n\n.our-teams .swiper-slide .item-name-skeleton {\n    font-weight: bold;\n    width: 50px;\n}\n\n.our-teams img {\n    border-radius: 50%;\n    border: 5px solid #b9a036;\n}\n\n.containt-businesshour .containt-businesshour,\n.containt-businesshour .containt-team,\n.containt-team {\n    border: 1px solid #e7e7e7;\n    backdrop-filter: blur(10px);\n    border-radius: 10px;\n    margin-bottom: 20px;\n    /* animation-name: fadeLeft;\n    animation-duration: 1s;\n    animation-fill-mode: both; */\n}\n\n/* @keyframes fadeLeft {\n    0% {\n        transform: translateX(-900px);\n    }\n\n    100% {\n        transform: translateX(0);\n    }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
