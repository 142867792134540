import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { store_data, client_url, client, getCookie, stripe_pk } from '../data/functions';
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import PaymentStatus from '../components/PaymentStatus';

const stripePromise = loadStripe(stripe_pk)

const PaymentSucess = () => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentStatus></PaymentStatus>
        </Elements>

    );
};
export default PaymentSucess; 