import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { client, getCookie } from '../data/functions';

const Believe = () => {
    const [text1, setText1] = useState();
    const [text2, setText2] = useState();
    const [text3, setText3] = useState();
    useEffect(() => {
        const store = sessionStorage.getItem('st');
        client.get('api/store/' + store + '/contact/',
            { withCredentials: true },
            {
                headers: { "X-CSRFToken": getCookie('csrftoken') },
            }
        ).then((res) => {
            setText1(res.data[0].text_1)
            setText2(res.data[0].text_2)
            setText3(res.data[0].text_3)
        })
    }, [])
    return (
        <>
            <div style={{ textTransform: 'uppercase' }} className='direction-left'>
                <h3>{sessionStorage.getItem('st_name')}, WHO WE ARE.</h3>
            </div>
            <div className='content-believe-text direction-left'>
                {text1}
            </div>
            <p style={{ textTransform: 'uppercase' }} className='direction-left'>
                {text2}
            </p>
            <p style={{ textTransform: 'uppercase' }} className='direction-left'>
                {text3}
            </p>
            <Button className='direction-left btn-hairpro' href='#section-schedule'>Make an appointment</Button>
        </>
    );
};

export default Believe;