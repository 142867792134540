// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-marquee {
  background: linear-gradient(90deg, #5f5735, #8a7507, #e8bc00, #62510a);
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/_marquee.css"],"names":[],"mappings":"AAAA;EACI,sEAAA;EACA,YAAA;AACJ","sourcesContent":[".section-marquee {\n    background: linear-gradient(90deg, #5f5735, #8a7507, #e8bc00, #62510a);\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
