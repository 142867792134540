import React, { useState } from 'react';
import Cropper from 'react-easy-crop'
import { getCroppedImg, getRotatedImage } from '../data/canvasUtils'
import { Button } from 'react-bootstrap';
import { getCookie, data, client } from '../data/functions';
import imageCompression from 'browser-image-compression';
import ToastComp from './Toast';

const CONTAINER_HEIGHT = 1900

const CropImage = (props) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, onZoomChange] = useState(1)
    const [croppedImage, setCroppedImage] = useState(null)
    const [imageSrc, setImageSrc] = useState(props.image)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [rotation, setRotation] = useState(0)
    const [toastShow, SetToastShow] = useState(false);
    // const [zoom, setZoom] = useState(1)

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const handleImageUpload = async (fileImage) => {
        const formData = new FormData()
        // console.log('originalFile instanceof Blob', fileImage instanceof Blob); // true
        // console.log(`originalFile size ${fileImage.size / 1024 / 1024} MB`);
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 350,
            useWebWorker: true,
        }
        try {
            const compressedFile = await imageCompression(fileImage, options);
            // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            const newFile = new File([compressedFile], compressedFile.name);
            // SetFile(newFile)
            formData.append('image', newFile)
            // formData.append(param1 + 'id', sessionStorage.getItem(props.itemSession))
            // client.post('api/' + props.url, formData,
            formData.append('id', sessionStorage.getItem('id'))
            client.post('api/user/change/', formData,
                { withCredentials: true },
                {
                    headers: { "X-CSRFToken": getCookie('csrftoken') },
                }
            ).then((res) => {
                return true
            }).catch((err) => {
                console.log(err);
                return false
            })
            return
        } catch (error) {
            console.log(error);
        }
    }

    const selectCroppedImage = async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
                rotation
            )
            // console.log('donee', { croppedImage })
            // setCroppedImage(croppedImage)
            document.getElementById('img').style.setProperty('background-image', 'url(' + croppedImage + ')')
            // console.log(typeof (croppedImage));
            SetToastShow(true)
            const date = new Date()
            fetch(croppedImage)
                .then(res => res.blob())
                .then(blob => {
                    const myFile = new File([blob], date.getTime() + '.' + blob.type.split('/')[1], { type: blob.type })
                    // console.log(myFile)
                    handleImageUpload(myFile)
                })
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <div className="App">
                <div className="crop-container">
                    <Cropper
                        image={props.image}
                        crop={crop}
                        zoom={zoom}
                        aspect={2.8 / 3}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                    // onMediaLoaded={(mediaSize) => {
                    //     // Adapt zoom based on media size to fit max height
                    //     onZoomChange(CONTAINER_HEIGHT / mediaSize.naturalHeight)
                    // }}
                    />
                </div>
                <div className="controls">
                    <input
                        type="range"
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e) => {
                            onZoomChange(e.target.value)
                        }}
                        className="zoom-range"
                    />
                    <Button variant="success" onClick={selectCroppedImage}>
                        Select
                    </Button>
                </div>
            </div>
            <ToastComp show={toastShow} delay={3000} bg={'info'} onClose={() => SetToastShow(false)} autohide body_content={'Selection done'} header_content={'You can close this box'}></ToastComp>
        </>

    );
};

export default CropImage;