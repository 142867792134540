import React from 'react';
import {
    FacebookShareButton,
    WhatsappShareButton,
    FacebookIcon,
    WhatsappIcon,
} from "react-share";

const SocilaShare = () => {
    const url = 'https://www.flexiserve.ca/' + sessionStorage.getItem('st')
    const storeTitle = '*' + sessionStorage.getItem('st_name') + '* come and see our services'
    const hstText = '#' + sessionStorage.getItem('st_name')
    return (
        <div className='container socialShare'>
            <FacebookShareButton url={url} hashtag={hstText} style={{ marginBottom: '5px' }}>
                <FacebookIcon size={38} round={true} />
            </FacebookShareButton>
            <WhatsappShareButton url={url} title={storeTitle} separator=' ==> '>
                <WhatsappIcon size={38} round={true} />
            </WhatsappShareButton>
        </div >
    );
};

export default SocilaShare;