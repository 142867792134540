import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { client_url } from '../data/functions';

const CopyLinkStore = () => {
    const navigate = useNavigate();
    const openStore = () => {
        setTimeout(() => {
            navigate('/' + sessionStorage.getItem('st'))
            sessionStorage.removeItem('ab')
        }, 1000);
        return
    }
    return (
        <div>
            <div style={{
                borderBottom: '1px solid #eaeaea',
                marginBottom: '15px'
            }}>
            </div>
            Copy your link :
            <input
                id="link"
                type="text"
                value={client_url + '/' + sessionStorage.getItem('st')}
                style={{
                    marginBottom: '20px',
                    width: '100%',
                    textAlign: 'center'
                }}
            />
            <br />
            <Button className='btn-hairpro' onClick={() => openStore(sessionStorage.getItem('st'))}>Go to your store</Button>
        </div>
    );
};

export default CopyLinkStore;