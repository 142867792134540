import React, { useState } from 'react';
import { Alert, Breadcrumb, Button, Card, Col, Container, Form, Modal, ProgressBar, Row, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { store_data, businessRegister } from '../data/functions';
import CategoryList from './CategoryList';

const FormBusinessInfo = () => {
    const schema = yup.object().shape({
        businessName: yup.string().required(),
        email: yup.string().required().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Enter a valid email format'),
        businessPhone: yup.string().required(),
        storeCategory: yup.string().required(),
    });

    const onSubmit = async (values, actions) => {
        document.getElementById('isSend').setAttribute('disabled', true);
        document.getElementById('loading_business_1').innerHTML = '<div class="text-center"><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>';
        document.getElementById('loading_business_1').style.display = 'block';
        await new Promise((resolve) => setTimeout(() => {
            store_data['name'] = values.businessName;
            store_data['email'] = values.email;
            store_data['tel'] = values.businessPhone;
            store_data['storeCategory'] = values.storeCategory;

            businessRegister(store_data, '1')

            var nv = document.getElementById("nav-business-created");
            var next = nv.querySelectorAll(".next");
            next[0].classList.remove('disabled')
        }, 1000))
        // actions.resetForm();
    }

    const { handleSubmit, handleChange, isSubmitting, values, touched, errors } = useFormik({
        validationSchema: schema,
        initialValues: {
            businessName: '',
            email: '',
            businessPhone: '',
            storeCategory: '',
        },
        onSubmit,
    });

    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <Row className='g-3'>
                    <Col sm={6} controlId="validationFormik01">
                        <Form.Label for='businessName'>Business name</Form.Label>
                        <Form.Control
                            type='text'
                            id='businessName'
                            value={values.businessName}
                            onChange={handleChange}
                            isValid={touched.businessName && !errors.businessName}
                            isInvalid={!!errors.businessName}
                            placeholder='business name'
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <div class="invalid-feedback">
                            Valid business name is required.
                        </div>
                    </Col>
                    <Col sm={6} controlId="validationFormik02">
                        <Form.Label for='email'>Email:</Form.Label>
                        <Form.Control
                            type='email'
                            id='email'
                            value={values.email}
                            onChange={handleChange}
                            isValid={touched.email && !errors.email}
                            isInvalid={!!errors.email}
                            placeholder='you@example.com'
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <div class="invalid-feedback">
                            Please enter a valid email address for billing.
                        </div>
                    </Col>
                    <Col sm={6} controlId="validationFormik03">
                        <Form.Label for='email'>Phone number:</Form.Label>
                        <Form.Control
                            type='text'
                            id='businessPhone'
                            value={values.businessPhone}
                            onChange={handleChange}
                            isValid={touched.businessPhone && !errors.businessPhone}
                            isInvalid={!!errors.businessPhone}
                            placeholder='111-111-1111'
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <div class="invalid-feedback">
                            Please enter a valid phone number.
                        </div>
                    </Col>
                    <Col sm={6} controlId="validationFormik04">
                        <Form.Group className="mb-3 mx-3" controlId="formSelectCategory">
                            <Form.Label>Caterory :</Form.Label>
                            <Form.Select
                                id='storeCategory'
                                onChange={handleChange}
                                isValid={touched.storeCategory && !errors.storeCategory}
                                isInvalid={!!errors.storeCategory}
                            >
                                <option value=''>...</option>
                                <CategoryList />
                            </Form.Select>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <div class="invalid-feedback">
                                Please select a category.
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <div id="loading_business_1" style={{ display: 'none', textAlign: 'center' }}></div>
                {/* disabled={isSubmitting} */}
                <Button type="submit" id='isSend' className='my-3'>Send&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5" />
                    <path fill-rule="evenodd" d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z" />
                </svg></Button>
            </Form>
        </div>
    );
};

export default FormBusinessInfo;