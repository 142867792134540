import React, { useState } from 'react';
import { Alert, Breadcrumb, Button, Card, Col, Container, Form, ProgressBar, Row, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { store_data, businessRegister } from '../data/functions';
import { initAutocomplete } from '../data/placeAutoComplete';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

const FormAddressInfo = () => {

    const schema = yup.object().shape({
        address: yup.string(),
        address2: yup.string(),
        country: yup.string(),
        city: yup.string(),
        state: yup.string(),
        zip: yup.string(),
    });

    const onSubmit = async (values, actions) => {
        await new Promise((resolve) => setTimeout(() => {
            document.getElementById('loading_business_2').innerHTML = '<div class="text-center"><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>';
            document.getElementById('loading_business_2').style.display = 'block';
            store_data['store_apt'] = values.address2;
            if (values.state) {
                store_data['store_address'] = values.address;
                store_data['store_country'] = values.country;
                store_data['store_city'] = values.city;
                store_data['store_state'] = values.state;
                store_data['store_cp'] = values.zip;
            }

            businessRegister(store_data, '2')

            var nv = document.getElementById("nav-business-created");
            var next = nv.querySelectorAll(".next");
            next[0].classList.remove('disabled')
        }, 1000))
        // actions.resetForm();
    }

    const { handleSubmit, handleChange, isSubmitting, values, touched, errors } = useFormik({
        validationSchema: schema,
        initialValues: {
            address: '',
            address2: '',
            country: '',
            city: '',
            state: '',
            zip: '',
        },
        onSubmit,
    });

    /////////// Auto Complete ///////////////
    let autocomplete;
    let address1Field;
    let address2Field;
    let postalField;

    address1Field = document.querySelector("#address");
    address2Field = document.querySelector("#address2");
    postalField = document.querySelector("#zip");
    // Create the autocomplete object, restricting the search predictions to
    // addresses in the US and Canada.
    autocomplete = new google.maps.places.Autocomplete(address1Field, {
        // componentRestrictions: { country: ["us", "ca"] },
        fields: ["address_components", "geometry"],
        types: ["address"],
    });
    // address1Field.focus();
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autocomplete.addListener("place_changed", fillInAddress);

    function fillInAddress() {
        // Get the place details from the autocomplete object.
        const place = autocomplete.getPlace();
        let address1 = "";
        let postcode = "";

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        // place.address_components are google.maps.GeocoderAddressComponent objects
        // which are documented at http://goo.gle/3l5i5Mr
        for (const component of place.address_components) {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    address1 = `${component.long_name} ${address1}`;
                    store_data['store_address'] = `${component.long_name} ${address1}`;
                    break;
                }

                case "route": {
                    address1 += component.short_name;
                    store_data['store_address'] = address1;
                    break;
                }

                case "postal_code": {
                    postcode = `${component.long_name}${postcode}`;
                    store_data['store_cp'] = `${postcode}`;
                    break;
                }

                case "postal_code_suffix": {
                    // postcode = `${postcode}-${component.long_name}`;
                    // store_data['store_cp'] = `${postcode}-${component.long_name}`;
                    break;
                }
                case "locality":
                    document.querySelector("#city").value = component.long_name;
                    store_data['store_city'] = component.long_name;
                    break;
                case "administrative_area_level_1": {
                    document.querySelector("#state").value = component.short_name;
                    store_data['store_state'] = component.short_name;
                    break;
                }
                case "country":
                    document.querySelector("#country").value = component.long_name;
                    store_data['store_country'] = component.long_name;
                    break;
            }
        }

        address1Field.value = address1;
        postalField.value = postcode;
        // After filling the form with address components from the Autocomplete
        // prediction, set cursor focus on the second address line to encourage
        // entry of subpremise information such as apartment, unit, or floor number.
        // address2Field.focus();
    }

    try {
        window.initAutocomplete = initAutocomplete;
    } catch (error) {
        console.log(error)
    }

    return (
        <div>
            <Form id="address-form" noValidate onSubmit={handleSubmit} method="get" autocomplete="off">
                <Row className='g-3'>
                    <Col md={6} controlId="validationFormik05">
                        <Form.Label for='address2'>Apartment, unit, suite, or floor # (optional)</Form.Label>
                        <Form.Control
                            type='text'
                            id='address2'
                            value={values.address2}
                            onChange={handleChange}
                            isValid={touched.address2 && !errors.address2}
                            isInvalid={!!errors.address2}
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <div class="invalid-feedback">
                            Please select a valid country.
                        </div>
                    </Col>
                    <Col sm={12} controlId="validationFormik01">
                        <Form.Label for='address'>Address (*)</Form.Label>
                        <Form.Control
                            type='text'
                            id='address'
                            placeholder='1234 Main St'
                            value={values.address}
                            onChange={handleChange}
                            isValid={touched.address && !errors.address}
                            isInvalid={!!errors.address}
                        />
                        <div class="invalid-feedback">
                            Address field is required.
                        </div>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Col>

                    <Col md={3} controlId="validationFormik02">
                        <Form.Label for='country'>Country (*)</Form.Label>
                        <Form.Control
                            type='text'
                            id='country'
                            value={values.country}
                            onChange={handleChange}
                            isValid={touched.country && !errors.country}
                            isInvalid={!!errors.country}
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <div class="invalid-feedback">
                            Please select a valid country.
                        </div>
                    </Col>
                    <Col md={3} controlId="validationFormik06">
                        <Form.Label for='city'>City (*)</Form.Label>
                        <Form.Control
                            type='text'
                            id='city'
                            value={values.city}
                            onChange={handleChange}
                            isValid={touched.city && !errors.city}
                            isInvalid={!!errors.city}
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <div class="invalid-feedback">
                            Please select a valid city.
                        </div>
                    </Col>
                    <Col md={3} controlId="validationFormik03">
                        <Form.Label for='state'>State/Province (*)</Form.Label>
                        <Form.Control
                            type='text'
                            id='state'
                            value={values.state}
                            onChange={handleChange}
                            isValid={touched.state && !errors.state}
                            isInvalid={!!errors.state}
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <div class="invalid-feedback">
                            Please provide a valid state.
                        </div>
                    </Col>
                    <Col md={3} controlId="validationFormik04">
                        <Form.Label for='zip'>Zip (*)</Form.Label>
                        <Form.Control
                            type='text'
                            id='zip'
                            value={values.zip}
                            onChange={handleChange}
                            isValid={touched.zip && !errors.zip}
                            isInvalid={!!errors.zip}
                            placeholder=''
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <div class="invalid-feedback">
                            Zip code required.
                        </div>
                    </Col>
                </Row>
                <div id="loading_business_2" style={{ display: 'none', textAlign: 'center' }}>
                    {/* <Alert variant='success' style={{ textAlign: 'center' }}>done!</Alert>  */}
                </div>
                <Button type="submit" disabled={isSubmitting} className='my-3'>Send&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5" />
                    <path fill-rule="evenodd" d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z" />
                </svg></Button>
            </Form>

        </div>
    );
};

export default FormAddressInfo;