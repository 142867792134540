// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.socialShare {
  position: sticky;
  top: 465px;
  z-index: 9999;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  height: 0px;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/_socialShare.css"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,UAAA;EACA,aAAA;EACA,aAAA;EACA,yBAAA;EACA,sBAAA;EACA,qBAAA;EACA,WAAA;AACJ","sourcesContent":[".socialShare {\n    position: sticky;\n    top: 465px;\n    z-index: 9999;\n    display: flex;\n    justify-content: flex-end;\n    flex-direction: column;\n    align-items: flex-end;\n    height: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
