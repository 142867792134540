import React from 'react';

const CategoryList = () => {
    return (
        <>
            <option value='Beauty'>Beauty/Barber/Hairstyles/Manicures</option>
            <option value='Foods'>Food service/Catering</option>
            <option value='Consultant'>Consultant/Advisor</option>
            <option value='Cleaning'>Cleaning</option>
            <option value='Other'>Other</option>
        </>
    );
};

export default CategoryList;