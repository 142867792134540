// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-galeries .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  /* height: 300px; */
}

.section-galeries .swiper-slide img {
  display: block;
  width: 100%;
  border-radius: 2rem;
}

.section-galeries .swiper-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.51), rgba(0, 0, 0, 0.51));
  border-radius: 2rem;
}

.section-galeries .swiper-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.51), rgba(0, 0, 0, 0.51));
  border-radius: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/_galeries.css"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,sBAAA;EACA,YAAA;EACA,mBAAA;AACJ;;AAEA;EACI,cAAA;EACA,WAAA;EACA,mBAAA;AACJ;;AAEA;EACI,oFAAA;EACA,mBAAA;AACJ;;AAEA;EACI,qFAAA;EACA,mBAAA;AACJ","sourcesContent":[".section-galeries .swiper-slide {\n    background-position: center;\n    background-size: cover;\n    width: 300px;\n    /* height: 300px; */\n}\n\n.section-galeries .swiper-slide img {\n    display: block;\n    width: 100%;\n    border-radius: 2rem;\n}\n\n.section-galeries .swiper-3d .swiper-slide-shadow-left {\n    background-image: linear-gradient(to left, rgb(0 0 0 / 51%), rgb(0 0 0 / 51%));\n    border-radius: 2rem;\n}\n\n.section-galeries .swiper-3d .swiper-slide-shadow-right {\n    background-image: linear-gradient(to right, rgb(0 0 0 / 51%), rgb(0 0 0 / 51%));\n    border-radius: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
