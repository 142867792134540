import React, { useState } from 'react';
import { Alert, Breadcrumb, Button, Card, Col, Container, Form, Modal, ProgressBar, Row, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { data, register } from '../data/functions';

const FormUserInfo = () => {
    const [modalTerms, SetmodalTerms] = useState(false);
    const schema = yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        email: yup.string().required().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Enter a valid email format'),
        password: yup.string().required(),
        confirm_password: yup.string().oneOf([yup.ref('password')], 'Your passwords do not match.').required(),
        terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
    });

    const onSubmit = async (values, actions) => {
        document.getElementById('loading_register').innerHTML = '<div class="text-center"><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>';
        document.getElementById('loading_register').style.display = 'block';
        await new Promise((resolve) => setTimeout(() => {
            data['username'] = values.firstName;
            data['lastname'] = values.lastName;
            data['email'] = values.email;
            data['password'] = values.password;
            data['superuser'] = true;

            register(data)

            var nv = document.getElementById("nav-business-created");
            var next = nv.querySelectorAll(".next");
            next[0].classList.remove('disabled')
        }, 1000))
        // actions.resetForm();
    }

    const { handleSubmit, handleChange, isSubmitting, values, touched, errors } = useFormik({
        validationSchema: schema,
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirm_password: '',
            terms: false,
        },
        onSubmit,
    });

    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <Row className='g-3'>
                    <Col sm={6} controlId="validationFormik01">
                        <Form.Label for='firstName'>First name</Form.Label>
                        <Form.Control
                            type='text'
                            id='firstName'
                            placeholder='first name'
                            value={values.firstName}
                            onChange={handleChange}
                            isValid={touched.firstName && !errors.firstName}
                            isInvalid={!!errors.firstName}
                        />
                        <div class="invalid-feedback">
                            Valid first name is required.
                        </div>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Col>
                    <Col sm={6} controlId="validationFormik02">
                        <Form.Label for='lastName'>Last name</Form.Label>
                        <Form.Control
                            type='text'
                            id='lastName'
                            value={values.lastName}
                            onChange={handleChange}
                            isValid={touched.lastName && !errors.lastName}
                            isInvalid={!!errors.lastName}
                            placeholder='last name'
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <div class="invalid-feedback">
                            Valid last name is required.
                        </div>
                    </Col>
                    <Col sm={12} controlId="validationFormik03">
                        <Form.Label for='email'>Email:</Form.Label>
                        <Form.Control
                            type='email'
                            id='email'
                            value={values.email}
                            onChange={handleChange}
                            isValid={touched.email && !errors.email}
                            isInvalid={!!errors.email}
                            placeholder='you@example.com'
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <div class="invalid-feedback">
                            Please enter a valid email address for billing.
                        </div>
                    </Col>
                    <Col sm={6} controlId="validationFormik04">
                        <Form.Label for='password'>Password</Form.Label>
                        <Form.Control
                            type='password'
                            id='password'
                            name='password'
                            value={values.password}
                            onChange={handleChange}
                            isValid={touched.password && !errors.password}
                            isInvalid={!!errors.password}
                            placeholder='create a password'
                        />
                        <div class="invalid-feedback">
                            Please enter valid password.
                        </div>
                    </Col>
                    <Col sm={6} controlId="validationFormik05">
                        <Form.Label for='confirm_password'>Confirm Password</Form.Label>
                        <Form.Control
                            type='password'
                            id='confirm_password'
                            name='confirm_password'
                            value={values.confirm_password}
                            onChange={handleChange}
                            isValid={touched.confirm_password && !errors.confirm_password}
                            isInvalid={!!errors.confirm_password}
                            placeholder='confirm password'
                        />
                        <Form.Control.Feedback>Password match.</Form.Control.Feedback>
                        <div class="invalid-feedback">
                            Password are not match.
                        </div>
                    </Col>
                    <span>Please read <a href="#" style={{ textDecoration: 'none', fontStyle: 'italic' }} onClick={() => SetmodalTerms(true)}> Terms and conditions</a></span>
                    <Form.Group className="mb-3">
                        <Form.Check
                            required
                            name="terms"
                            label="Agree to terms and conditions"
                            onChange={handleChange}
                            isInvalid={!!errors.terms}
                            feedback={errors.terms}
                            feedbackType="invalid"
                            id="terms"
                        />
                    </Form.Group>
                </Row>
                <div id="loading_register" style={{ display: 'none', textAlign: 'center' }}>
                    {/* <Alert variant='success' style={{ textAlign: 'center' }}>done!</Alert> */}
                </div>
                {/* disabled={isSubmitting} */}
                <Button type="submit" className='my-3' disabled={isSubmitting}>Send&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5" />
                    <path fill-rule="evenodd" d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z" />
                </svg></Button>
            </Form>
            <Modal
                show={modalTerms}
                onHide={() => SetmodalTerms(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                animation={true}
                scrollable="true"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Terms & Conditions
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-terms'>
                    <b>1. Introduction</b>
                    <p>Bienvenue sur notre plateforme. En utilisant notre application, vous acceptez de respecter les termes et conditions décrits ci-dessous. Cette plateforme permet aux utilisateurs de proposer leurs services en ligne et de permettre à leurs clients de prendre des rendez-vous pour bénéficier de ces services.</p>

                    <b>2. Responsabilité des Prestataires de Services</b>
                    <p>Les utilisateurs de notre plateforme qui proposent des services sont entièrement responsables de la légalité de leurs activités professionnelles. Cela inclut, sans s'y limiter, l'obtention de toutes les licences, certifications ou autorisations nécessaires pour exercer leur métier conformément aux lois en vigueur dans leur juridiction.</p>

                    <b>3. Non-Responsabilité en Cas de Non-Conformité</b>
                    <p>La société <b>CanWeb Innov Inc.</b> décline toute responsabilité en cas de poursuites judiciaires, d'amendes ou de pénalités résultant de l'exercice illégal d'une profession par un utilisateur de la plateforme. Il est de la responsabilité exclusive de chaque prestataire de service de s'assurer qu'il est en conformité avec les lois locales, étatiques et nationales applicables à son domaine d'activité.</p>

                    <b>4. Vérification des Licences et Certifications</b>
                    <p>La plateforme ne vérifie pas de manière proactive les licences ou certifications des prestataires de services. En conséquence, les clients sont invités à effectuer leurs propres vérifications concernant les qualifications du prestataire avant de prendre un rendez-vous.</p>

                    <b>5. Indemnisation</b>
                    <p>En utilisant cette plateforme, vous acceptez de dégager <b>CanWeb Innov Inc.</b> de toute responsabilité et de l'indemniser contre toute réclamation, action en justice, ou perte résultant de votre non-conformité aux obligations légales de votre profession.</p>

                    <b>6. Modifications des Termes et Conditions</b>
                    <p><b>CanWeb Innov Inc.</b> se réserve le droit de modifier ces termes et conditions à tout moment. Les utilisateurs seront informés de toute modification importante via l'application ou par e-mail.</p>

                    <b>7. Responsabilité des Prestataires</b>
                    <p>En vous inscrivant en tant que prestataire de services sur <b>CanWeb Innov Inc.</b>, vous reconnaissez et acceptez que vous êtes seul responsable de l'obtention et du maintien de toutes les licences et autorisations nécessaires pour exercer votre métier. Vous vous engagez également à indemniser et dégager de toute responsabilité <b>CanWeb Innov Inc.</b>, ses dirigeants, employés et partenaires, en cas de réclamations, poursuites judiciaires, ou sanctions légales liées à votre activité professionnelle.</p>

                    <b>8. Litiges et Poursuites Judiciaires</b>
                    <p>Dans le cas où un client ou une tierce partie engagerait des poursuites judiciaires contre vous en raison de votre activité exercée via notre plateforme, <b>CanWeb Innov Inc.</b> ne sera en aucun cas tenu responsable. Vous acceptez d'assumer l'entière responsabilité de toute réclamation, et vous comprenez que l'utilisation de notre plateforme ne vous protège pas contre les obligations légales liées à votre métier.</p>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default FormUserInfo;