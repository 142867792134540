// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-img {
  width: 100%;
  height: 400px;
  position: absolute;
  /* left: 10%; */
  /* background-color: aqua; */
  top: 65px;
  /* background-image: url('/img/bg-hairpro_05.png'); */
  background-size: cover;
  background-position-x: 130px;
  background-size: 600px;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-repeat: no-repeat;
}
@media screen and (max-width: 1024px) {
  .carousel-img {
    /* left: -438px; */
    width: -webkit-fill-available;
    background-position-x: -300px;
  }
}

.carousel-caption {
  z-index: 1;
}
@media screen and (max-width: 850px) {
  .carousel-caption {
    right: 5% !important;
    width: 60% !important;
  }
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 35%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
  width: 30%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/_carouselcomp.css"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EACA,4BAAA;EACA,SAAA;EACA,qDAAA;EACA,sBAAA;EACA,4BAAA;EACA,sBAAA;EACA,kCAAA;EACA,0BAAA;EACA,4BAAA;AACJ;AACI;EAfJ;IAgBQ,kBAAA;IACA,6BAAA;IACA,6BAAA;EAEN;AACF;;AACA;EACI,UAAA;AAEJ;AAAI;EAHJ;IAIQ,oBAAA;IACA,qBAAA;EAGN;AACF;;AAAA;EACI,kBAAA;EACA,UAAA;EACA,eAAA;EACA,SAAA;EACA,oBAAA;EACA,uBAAA;EACA,WAAA;EACA,kBAAA;EACA,UAAA;EACA,oBAAA;EACA,qBAAA;AAGJ","sourcesContent":[".carousel-img {\n    width: 100%;\n    height: 400px;\n    position: absolute;\n    /* left: 10%; */\n    /* background-color: aqua; */\n    top: 65px;\n    /* background-image: url('/img/bg-hairpro_05.png'); */\n    background-size: cover;\n    background-position-x: 130px;\n    background-size: 600px;\n    -webkit-backdrop-filter: blur(2px);\n    backdrop-filter: blur(2px);\n    background-repeat: no-repeat;\n\n    @media screen and (max-width: 1024px) {\n        /* left: -438px; */\n        width: -webkit-fill-available;\n        background-position-x: -300px;\n    }\n}\n\n.carousel-caption {\n    z-index: 1;\n\n    @media screen and (max-width: 850px) {\n        right: 5% !important;\n        width: 60% !important;\n    }\n}\n\n.carousel-caption {\n    position: absolute;\n    right: 15%;\n    bottom: 1.25rem;\n    left: 35%;\n    padding-top: 1.25rem;\n    padding-bottom: 1.25rem;\n    color: #fff;\n    text-align: center;\n    width: 30%;\n    padding-left: .5rem;\n    padding-right: .5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
