// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-page {
  /* --bg-image: url('/img/bg-hairpro_02.jpg'); */
  --bg-image-opacity: .25;
  padding: 15px 0 35px 0;
  position: relative;
  isolation: isolate;
}

.about-page::before {
  content: "";
  position: absolute;
  inset: 0;
  background: gray;
  z-index: -1;
  opacity: var(--bg-image-opacity);
  /* background-image: var(--bg-image); */
  background-size: contain;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/_about.css"],"names":[],"mappings":"AAAA;EACI,+CAAA;EACA,uBAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,WAAA;EACA,kBAAA;EACA,QAAA;EACA,gBAAA;EACA,WAAA;EACA,gCAAA;EACA,uCAAA;EACA,wBAAA;AACJ","sourcesContent":[".about-page {\n    /* --bg-image: url('/img/bg-hairpro_02.jpg'); */\n    --bg-image-opacity: .25;\n    padding: 15px 0 35px 0;\n    position: relative;\n    isolation: isolate;\n}\n\n.about-page::before {\n    content: '';\n    position: absolute;\n    inset: 0;\n    background: gray;\n    z-index: -1;\n    opacity: var(--bg-image-opacity);\n    /* background-image: var(--bg-image); */\n    background-size: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
