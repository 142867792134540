import React, { useEffect, useState } from 'react';
import { client, getCookie, store_data } from '../data/functions';

const Marquee = () => {
    const [marquee, setMarquee] = useState();
    useEffect(() => {
        const store = sessionStorage.getItem('st');
        client.get('api/store/' + store + '/contact/',
            { withCredentials: true },
            {
                headers: { "X-CSRFToken": getCookie('csrftoken') },
            }
        ).then((res) => {
            setMarquee(res.data[0].scrolling_texte)
        })
    }, [])
    return (
        <div style={{ height: '35px', padding: '5px 0 5px 0' }}>
            <marquee behavior="" direction="left">
                {marquee}
            </marquee>
        </div>
    );
};

export default Marquee;