import Carousel from 'react-bootstrap/Carousel';
import { backgroundImage, textOnSlide, getCateg } from '../data/functions';

function Mycarousel() {
    let getTitle = textOnSlide(getCateg)
    return (
        <>
            <Carousel data-bs-theme="dark">
                <Carousel.Item
                    style={{
                        width: '100%',
                        height: '400px',
                        backgroundSize: 'cover',
                        backgroundPositionY: 'center',
                        backgroundImage: `url(${backgroundImage.img_1})`
                    }}
                >
                    <Carousel.Caption className='direction-left-static'>
                        <h5>{getTitle['slide_1'][0]}</h5>
                        <p>{getTitle['slide_1'][1]}</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item
                    style={{
                        width: '100%',
                        height: '400px',
                        backgroundSize: 'cover',
                        backgroundPositionY: 'center',
                        backgroundImage: `url(${backgroundImage.img_2})`
                    }}
                >
                    <Carousel.Caption className='direction-left-static'>
                        <h5>{getTitle['slide_2'][0]}</h5>
                        <p>{getTitle['slide_2'][1]}</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item
                    style={{
                        width: '100%',
                        height: '400px',
                        backgroundSize: 'cover',
                        backgroundPositionY: 'center',
                        backgroundImage: `url(${backgroundImage.img_3})`
                    }}
                >
                    <Carousel.Caption className='direction-left-static'>
                        <h5>{getTitle['slide_3'][0]}</h5>
                        <p>{getTitle['slide_3'][1]}</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            <div style={{ backgroundImage: `url(${backgroundImage.img_6})` }} className='carousel-img direction-left-static'></div>
        </>

    );
}

export default Mycarousel;