import React, { useEffect, useState } from 'react';
import { client, getCookie } from '../data/functions';

const MapLocation = () => {
    const [url, Seturl] = useState();
    useEffect(() => {
        const store = sessionStorage.getItem('st');
        client.get('api/store/' + store + '/contact/',
            { withCredentials: true },
            {
                headers: { "X-CSRFToken": getCookie('csrftoken') },
            }
        ).then((res) => {
            const adr = res.data[0].address.toLowerCase()
            const cp = res.data[0].cp.toLowerCase()
            const name = res.data[0].name
            const url_start = 'https://maps.google.com/maps?width=100%25&height=600&hl=en&q=' + adr.replaceAll(' ', '%20') + ',' + cp.replaceAll(' ', '') + '+(' + name.replaceAll(' ', '%20') + ')'
            const url_end = '&t=&z=14&ie=UTF8&iwloc=B&output=embed'
            Seturl(url_start + url_end)
            console.log(url_start + url_end)
        }).catch((err) => {
            console.log(err)
        })
    }, [])
    return (
        <div>

            <div style={{ width: '100%', display: 'flex', borderBottom: '10px solid #caa405', borderTop: '10px solid #caa405' }}>
                {/* <iframe width="100%" height="450" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=40%20donald%20avenue,%20e1a3b1+(Jenny%20Hairstyle)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"> */}
                <iframe width="100%" height="450" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src={url}>
                    <a href="https://www.gps.ie/">gps trackers</a>
                </iframe>


            </div>
        </div >
    );
};

export default MapLocation;