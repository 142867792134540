import React, { useContext, useEffect, useState } from 'react';
import Mynavbar from '../components/Navbarcomp';
import Headerpage from '../components/Headerpage';
import { Breadcrumb, Alert, Image, Form, Button, Spinner, Tabs, Tab, Modal, Badge } from 'react-bootstrap';
import { BsXLg, BsCameraFill } from "react-icons/bs";
import Contact from '../components/Contact';
import Iconbutton from '../components/Iconbutton';
import Footer from '../components/Footer';
import { getCookie, data, client } from '../data/functions';
import { AppContext } from '../reducers/AppContext';


const MyAppointment = (props) => {
    const { dispatch } = useContext(AppContext);
    const [countAppointment, SetCountAppointment] = useState();
    const [schedulelist, SetScheduleList] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [device, SetDevice] = useState();
    const [image, SetImage] = useState();
    const [price, SetPrice] = useState();
    const [name, SetName] = useState();

    let tab = [];
    let alldata = [];
    //Requete au lancement de la page afin de recuillir les infos de l'user
    useEffect(() => {
        document.getElementById('loading').innerHTML = '<div class="text-center"><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>';
        // Get the list of appointment make by Cx
        getCxAppointment()

    }, [])
    const getCxAppointment = () => {
        client.get('api/store/appointment/user/' + sessionStorage.getItem('id') + '/',
            { withCredentials: true },
            {
                headers: { "X-CSRFToken": getCookie('csrftoken') },
            }
        ).then((res) => {
            if (res.data.length <= 0) {
                setTimeout(() => {
                    try {
                        document.getElementById('loading').innerHTML = '<div class="alert alert-warning capitalize-hp" role="alert">No appointment yet!</div>';
                    } catch (error) {
                        console.log(error)
                    }

                }, 2000);
            } else {
                res.data.map((data, i) => {
                    client.get('api/store/schedule/' + data.schedule + '/',
                        { withCredentials: true },
                        {
                            headers: { "X-CSRFToken": getCookie('csrftoken') },
                        }
                    ).then((result) => {
                        client.get('api/user/' + data.user + '/',
                            { withCredentials: true },
                            {
                                headers: { "X-CSRFToken": getCookie('csrftoken') },
                            }
                        ).then((result_2) => {
                            alldata.push(result.data[0].date_created)
                            alldata.push(result.data[0].start.split('T')[1].slice(0, 5))
                            alldata.push(result_2.data[0].pseudo)
                            alldata.push(data.choice)
                            alldata.push(data.hairstyle)
                            alldata.push(data.id)
                            alldata.push(result_2.data[0].tel)
                            tab[i] = alldata

                            alldata = []
                            setTimeout(() => {
                                SetCountAppointment(tab.length)
                                SetScheduleList(tab)
                                document.getElementById('loading').style.display = 'block';
                            }, 10);
                            dispatch({});

                        }).catch((err) => {
                            console.log(err);
                            return false
                        })
                    }).catch((err) => {
                        console.log(err);
                        return false
                    })
                }
                )
            }

        }).catch((err) => {
            console.log(err);
            return false
        })
    }

    const viewAppointment = (id) => {
        client.get('api/store/hairstyle/' + id + '/',
            { withCredentials: true },
            {
                headers: { "X-CSRFToken": getCookie('csrftoken') },
            }
        ).then((res) => {
            SetDevice(res.data[0].device)
            SetImage(res.data[0].image)
            SetPrice(res.data[0].price)
            SetName(res.data[0].name)
            setShow(true)
        }).catch((err) => {
            console.log(err)
        })
    }
    const doneAppointment = (apptm) => {
        client.post('api/appointment/done/',
            { id: apptm },
            { withCredentials: true },
            {
                headers: { "X-CSRFToken": getCookie('csrftoken') },
            }
        ).then((res) => {
            getCxAppointment()
            return true
        }).catch((err) => {
            console.log(err);
            return false
        })
    }
    return (
        <div id='scrollup'>
            <Mynavbar></Mynavbar>
            <Headerpage title={props.title}></Headerpage>
            <div className="container" style={{
                marginBottom: '100px'
            }}>
                <br />
                <Breadcrumb>
                    <Breadcrumb.Item href={props.storeid}>Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>{props.title}</Breadcrumb.Item>
                </Breadcrumb>
                <br />
                <Tabs
                    defaultActiveKey="appointment"
                    id="tab-hairpro"
                    className="mb-3"
                >
                    <Tab eventKey="appointment"
                        title={
                            <React.Fragment>
                                Appointment
                                &nbsp;<Badge bg="danger" class="position-absolute top-0 start-100 translate-middle rounded-pill">{countAppointment}</Badge>
                            </React.Fragment>
                        }
                    >
                        <div className="section-profile">
                            <div className='my-3'>
                                <ul style={{
                                    listStyle: 'none',
                                    padding: '5px'
                                }}>
                                    {
                                        schedulelist.map((schedule, i) => {
                                            if (i === schedulelist.length - 1) {
                                                setTimeout(() => {
                                                    document.getElementById('loading').style.display = 'none';
                                                }, 500);

                                            }
                                            return (
                                                <li className="list-time" key={i}>
                                                    <span>{schedule[0]} | {schedule[1]} for <b>{schedule[2]}</b><br />Phone : {schedule[6]}</span>
                                                    {
                                                        (schedule[3] == 'yes') ? <Button variant="success" className="ms-4" onClick={() => { viewAppointment(schedule[4]) }}>View</Button> : ''
                                                    }
                                                    <Button variant="success" className="ms-2" onClick={() => {
                                                        doneAppointment(schedule[5])
                                                    }}>Done</Button>
                                                </li>
                                            )
                                        }
                                        )
                                    }

                                </ul>
                                <div id="loading" style={{ display: 'block', textAlign: 'center' }}></div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div >
            <div className="section-contact" id="section-contact">
                <div className="container">
                    <Contact></Contact>
                </div>
            </div>
            {/* Modal to show cx appointment */}
            <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable="true"
            >
                <Modal.Header closeButton>
                    <Modal.Title>View hairstyle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='content-user-info'>
                        <Image id='' src={image} className='mx-2' width='150px' height="150px" rounded />
                        <div id='user-info'>
                            <div><b>{name}</b></div>
                            <div><b>{price}{device}</b></div>
                        </div>
                    </div>
                    <div id="loading_1" style={{ margin: '5px 0 5px', display: 'none', textAlign: 'center' }}>
                        <Spinner animation="border" />
                    </div>
                </Modal.Body>
            </Modal>
            <Iconbutton></Iconbutton>
            <Footer></Footer>
        </div >
    );
};

export default MyAppointment;