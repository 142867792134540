import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { client, getCookie } from '../data/functions';

const CloseAccount = () => {
    const [deleteStoreShow, SetDeleteStoreShow] = useState(false);

    const handleClose_deleteStore = () => SetDeleteStoreShow(false);

    const deleteStore = () => {
        client.post('api/store/delete/',
            { st: sessionStorage.getItem('st') },
            { withCredentials: true },
            {
                headers: { "X-CSRFToken": getCookie('csrftoken') },
            }
        ).then(() => {
            client.post('api/logout/',
                { withCredentials: true },
                {
                    headers: { "X-CSRFToken": getCookie('csrftoken') },
                }
            ).then(() => {
                sessionStorage.removeItem('id');
                sessionStorage.removeItem('user');
                sessionStorage.removeItem('email');
                sessionStorage.removeItem('super');
                sessionStorage.removeItem('key');
                sessionStorage.removeItem('st_name');
                sessionStorage.removeItem('st');
                window.open('/', '_self');
            }).catch((err) => {
                console.log(err);
            })
            return true
        }).catch((err) => {
            console.log(err);
            return false
        })
    }
    return (
        <div>
            <Button variant="danger"
                onClick={(e) => {
                    SetDeleteStoreShow(true)
                }}
            >Delete store</Button>
            {/* Modal asking for delete confirmation */}
            <Modal
                show={deleteStoreShow}
                onHide={handleClose_deleteStore}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable="true"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Do you want to delete your store?<br />
                    <small><i>This action its irreversible</i></small>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={deleteStore}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CloseAccount;