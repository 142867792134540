import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { client, getCookie, oldUser, selectUser } from '../data/functions';

const CheckUser = () => {
    const [users, SetUsers] = useState([]);
    useEffect(() => {
        // SetIsloading(true)
        const store = sessionStorage.getItem('st');
        client.get('api/store/' + store + '/team/',
            { withCredentials: true },
            {
                headers: { "X-CSRFToken": getCookie('csrftoken') },
            }
        ).then((res) => {
            // SetIsloading(false)
            SetUsers(res.data)
            {
                (res.data).map((user, i) => (
                    oldUser.push(user.id)
                ))
            }
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const userSelect = (e) => {
        if (e.target.checked === true) {
            selectUser.push(e.target.id)
            // console.log(selectUser)
        } else {
            const isThere = (element) => element == e.target.id;
            const findIndex = selectUser.findIndex(isThere)
            selectUser.splice(findIndex, 1)
            // console.log(selectUser)
        }
    }
    return (
        <div style={{ border: '1px solid white', padding: '10px', marginBottom: '15px' }}>
            <span>Select who are able to perform that service :</span><br />
            <Form className='mb-4'>
                {users.map((user, i) => (
                    <Form.Check
                        inline
                        label={user.pseudo}
                        name={`group${i}`}
                        type='checkbox'
                        id={user.id}
                        key={i}
                        onChange={(e) => userSelect(e)}
                    />
                ))}
            </Form>
        </div>

    );
};

export default CheckUser;