import React, { useEffect, useState } from 'react';
import { client, client_url, getCookie, store_data } from '../data/functions';
import { useNavigate } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { Button } from 'react-bootstrap';
import CopyLinkStore from './CopyLinkStore';

const PaymentStatus = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [statusPayment, setStatusPayment] = useState(false);
    const stripe = useStripe();
    const formData = new FormData()

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    ///// Requete pour activer la boutique du client //////////
                    const getStoreId = sessionStorage.getItem('st')
                    const getAbonnement = sessionStorage.getItem('ab')
                    let numDay = ''

                    switch (getAbonnement) {
                        case 'mo':
                            numDay = 31
                            break;
                        case 'an':
                            numDay = 365
                            break;
                        default:
                            break;
                    }

                    formData.append('storeId', getStoreId)
                    formData.append('numDay', numDay)

                    client.post('api/user/change/', formData,
                        {
                            headers: { "X-CSRFToken": getCookie('csrftoken') },
                        }
                    ).then((res) => {
                        console.log(res.data);
                        return true
                    }).catch((err) => {
                        console.log(err);
                        return false
                    })
                    setMessage("Payment succeeded your store is active!");
                    setStatusPayment(true)
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    return (
        <div className='sucess-page'>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                borderBottom: '1px solid #eaeaea;',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100vh',
                paddingTop: '30px'
            }}>
                <div style={{
                    border: '1px solid #e7e7e7',
                    backdropFilter: 'blur(10px)',
                    webkitBackdropFilter: 'blur(10px)',
                    borderRadius: '10px',
                    marginBottom: '20px',
                    padding: '30px',
                    textAlign: 'center',
                    margin: '10px'
                }}>
                    <center><h3>{message && <div id="payment-message">{message}</div>}</h3></center>
                    {
                        (statusPayment) ? <CopyLinkStore /> : ''
                    }
                </div>
            </div>
        </div>
    );
};

export default PaymentStatus;