import React, { useEffect } from 'react';
import Mynavbar from '../components/Navbarcomp';
import Mycarousel from '../components/Carouselcomp';
import Freshcut from '../components/Freshcut';
import Calender from '../components/Calender';
import { Col, Row } from 'react-bootstrap';
import Businesshour from '../components/Businesshour';
import Galeries from '../components/Galeries';
import Testimonie from '../components/Testimonie';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Iconbutton from '../components/Iconbutton';
import { useParams } from 'react-router-dom';
import Teams from '../components/Teams';
import Marquee from '../components/Marquee';
import { animation, client, client_url, getCookie } from '../data/functions';
import Believe from '../components/Believe';
import MapLocation from '../components/MapLocation';
import { backgroundImage } from '../data/functions';
import SocilaShare from '../components/SocilaShare';

console.log('in')

const Home = () => {
    window.addEventListener('scroll', animation);
    const ct = sessionStorage.getItem('ct')
    console.log('in')
    const { id } = useParams();
    if (id.includes('section-')) {
        const sectionSpecifique = document.getElementById(id);
        if (sectionSpecifique) {
            window.scrollTo({
                top: sectionSpecifique.offsetTop,
                behavior: 'smooth',
            });
        }
    } else {
        sessionStorage.setItem('st', id);
    }

    useEffect(() => {
        if (id.includes('section-')) {
            const sectionSpecifique = document.getElementById(id);
            if (sectionSpecifique) {
                window.scrollTo({
                    top: sectionSpecifique.offsetTop,
                    behavior: 'smooth',
                });
            }
        } else {
            sessionStorage.setItem('st', id);
        }
    }, []);

    return (
        <div id='scrollup'>
            <SocilaShare></SocilaShare>
            <Mynavbar></Mynavbar>
            <Mycarousel></Mycarousel>

            <div className='section-marquee'>
                <div className="container">
                    <Marquee></Marquee>
                </div>
            </div>
            <div className="section-freshcuts" style={{ backgroundImage: `url(${backgroundImage.img_7})` }}>
                <div className="container">
                    <h1 className='section-title direction-left-static'>Our services</h1>
                    <br />

                    <Freshcut></Freshcut>
                </div>
            </div>

            <div className="section-schedule parallax" id="section-schedule" style={{ backgroundImage: `url(${backgroundImage.img_5})` }}>
                <div className="container">
                    <h1 className='section-title direction-left'>Appointment</h1>
                    <br />
                    <Row xs={1} md={2} lg={2}>
                        <Col>
                            <Businesshour></Businesshour>
                        </Col>
                        <Col className='containt-calendar direction-left'>
                            <Calender></Calender>
                        </Col>
                    </Row>

                </div>
            </div>
            <div className="section-galeries" id="section-galeries">
                <div className="container">
                    <h1 className='section-title direction-left'>Galeries</h1>
                    <br />
                    <Galeries></Galeries>
                </div>
            </div>
            <div className="section-believe" id="section-believe" style={{ backgroundImage: `url(${backgroundImage.img_4})` }}>
                <div className="container">
                    <h1 className='section-title direction-left'>What we believe</h1>
                    <br />
                    <Believe></Believe>
                </div>
            </div>
            <div className="section-testimonies parallax" id="section-testimonies" style={{ backgroundImage: `url(${backgroundImage.img_8})` }}>
                <div className="container">
                    <h1 className='section-title direction-left'>What they said about us</h1>
                    <Testimonie></Testimonie>
                </div>
            </div>
            <div className="section-location">
                <MapLocation></MapLocation>
            </div>
            <div className="section-contact" id="section-contact">
                <div className="container">
                    <Contact></Contact>
                </div>
            </div>
            {/* <div class="b-divider"></div> */}
            <Iconbutton></Iconbutton>
            <Footer></Footer>
        </div >
    );
};

export default Home;