import React, { useContext, useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import teamschedule from '../actions';
import { AppContext } from '../reducers/AppContext';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// Import Skeleton
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// import required modules
import { Pagination } from 'swiper/modules';
import { client, getCookie, selectUser, viewAllBtn } from '../data/functions';

const TeamsInModal = (props) => {
    const [users, SetUsers] = useState([]);
    const [isloading, SetIsloading] = useState(false);
    const numTeam = [0, 0, 0, 0, 0];

    useEffect(() => {
        SetIsloading(true)
        const store = sessionStorage.getItem('st');
        client.get('api/store/' + store + '/team/',
            { withCredentials: true },
            {
                headers: { "X-CSRFToken": getCookie('csrftoken') },
            }
        ).then((res) => {
            SetIsloading(false)
            SetUsers(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const { dispatch } = useContext(AppContext);
    return (
        <div className='our-teams mb-3'>
            <h4 style={{ color: 'yellow' }}>Our teams</h4>
            <p>Check availability</p>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={15}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
            >
                {
                    (isloading) ?
                        numTeam.map((users, i) =>
                            <SwiperSlide key={i}>
                                <div className='item'>
                                    <Skeleton
                                        circle
                                        width={80}
                                        height={80}
                                        baseColor="#cbbd87"
                                        highlightColor="#e2e2e2"
                                    />
                                    <Skeleton
                                        baseColor="#cbbd87"
                                        highlightColor="#e2e2e2"
                                        containerClassName={'item-name-skeleton'} />
                                </div>
                            </SwiperSlide>
                        ) :
                        users.filter((item) => {
                            return (props.service) ? item.list_service.includes(props.service) : item
                        }).map((user, i) =>
                            <SwiperSlide key={i}>
                                <div className='item'>
                                    <Image onClick={(event) => {
                                        dispatch(teamschedule(user.id));
                                        var elem = document.querySelectorAll('.team-user');
                                        for (let i = 0; i < elem.length; i++) {
                                            elem[i].classList.remove('active');
                                        }
                                        event.target.className += ' active';
                                        document.getElementById('contentCalendar').style.display = 'block'
                                    }} className='team-user' id={user.id} src={user.image} width="100px" height="100px" />
                                    <span id='team-name'>{user.pseudo}</span>
                                </div>
                            </SwiperSlide>
                        )
                }
            </Swiper>
            {/* <Button className='btn-hairpro' id='contentButtonViewAll' onClick={() => {
                var elem = document.querySelectorAll('.team-user');
                for (let i = 0; i < elem.length; i++) {
                    elem[i].classList.remove('active');
                }
                dispatch({
                    type: 'SELECT-ALL-BARBER',
                })
            }}>View all</Button> */}

        </div>
    );
};

export default TeamsInModal;