import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import { AppProvider } from './reducers/AppContext'; //Composant de notre Context(AppContext) qui englobera toute notre app
import Profile from './pages/MySpace';
import CoverPage from './pages/CoverPage';
import CreateBusiness from './pages/CreateBusiness';
import Manage from './pages/Manage';
import Pricing from './pages/Pricing';
import PaymentSucess from './pages/PaymentSucess';
import InactiveStore from './pages/InactiveStore';
import MyAppointment from './pages/MyAppointment';

const App = () => {
  const id = sessionStorage.getItem('st')
  return (
    <AppProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<CoverPage />}></Route>
          <Route exact path='/payment-sucess' element={<PaymentSucess />}></Route>
          <Route exact path='/store-no-available' element={<InactiveStore />}></Route>
          <Route exact path='/:id' element={<Home />}></Route>
          {/* <Route exact path='/:section' element={<Home />}></Route> */}
          <Route exact path='/create-my-business' element={<CreateBusiness title={'Business checkout'} />}></Route>
          <Route exact path='/about' element={<About storeid={id} title={'About us'} />}></Route>
          <Route exact path='/my-space' element={<Profile storeid={id} title={'My Space'} />}></Route>
          <Route exact path='/my-appointment' element={<MyAppointment storeid={id} title={'My Appointments'} />}></Route>
          <Route exact path='/dashboard' element={<Manage storeid={id} title={'Dashboard'} />}></Route>
          <Route exact path='/pricing' element={<Pricing storeid={id} title={'Pricing'} />}></Route>
          <Route exact path='*' element={<Home />}></Route>
        </Routes>
      </BrowserRouter>
    </AppProvider>
  );
};

export default App;