import { useState, useEffect, useContext } from 'react';
import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap';
import Teams from './Teams';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { client, getCookie, selectUser, viewAllBtn } from '../data/functions';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// Import Skeleton
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// import required modules
import { Pagination } from 'swiper/modules';
import Calender from './Calender';
import TeamsInModal from './TeamsInModal';
import { AppContext } from '../reducers/AppContext';

function Freshcut() {
    const [show, setShow] = useState(false);
    const [hidden, setHidden] = useState(true);
    const [imgfreshcut, setImgfreshcut] = useState();
    const [id, setId] = useState();
    const [price, setPrice] = useState();
    const [description, setDescription] = useState();
    const [device, setDevice] = useState();
    const [name, setName] = useState();
    const [hairstyles, SetHairstyles] = useState([]);
    const [isloading, SetIsloading] = useState(false);
    const { dispatch } = useContext(AppContext);

    const handleClose = () => {
        setShow(false);
        dispatch({
            type: 'SELECT-ALL-BARBER',
        })
    }
    const handleHidden = () => setHidden(true);

    const numFrescut = [0, 0, 0, 0, 0];

    useEffect(() => {
        SetIsloading(true)
        const store = sessionStorage.getItem('st');
        client.get('api/store/' + store + '/hairstyle/',
            { withCredentials: true },
            {
                headers: { "X-CSRFToken": getCookie('csrftoken') },
            }
        ).then((res) => {
            SetIsloading(false)
            SetHairstyles(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    return (
        <div className='freshcutlist'>
            <h4 className='direction-left-static' style={{ textAlign: 'center' }}>Choose your need and book an appointment!</h4>
            <br />
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={15}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="direction-left-static"
            >
                {
                    (isloading) ?
                        numFrescut.map((hairstyle, i) =>
                            <SwiperSlide key={i}>
                                <div className='item'>
                                    <Skeleton
                                        // width={250}
                                        height={300}
                                        baseColor="#cbbd87"
                                        highlightColor="#e2e2e2"
                                        duration={3}
                                    />
                                    <Skeleton
                                        baseColor="#474747"
                                        containerClassName={'freshcut-price-skeleton'} />
                                </div>
                            </SwiperSlide>
                        )
                        :
                        hairstyles.map((hairstyle, i) =>
                            <SwiperSlide key={i}>
                                <div className='item'>
                                    <Image onClick={() => {
                                        chooseCut(hairstyle.id, hairstyle.image, hairstyle.price, hairstyle.description, hairstyle.device, hairstyle.name)
                                    }} id={hairstyle.id} src={hairstyle.image} width="100%" height="300px" alt='flexiserve_service_img' />
                                    <div className='freshcut-price'>{hairstyle.price}{hairstyle.device}</div>
                                    <div className='freshcut-name'>{hairstyle.name}</div>
                                </div>
                            </SwiperSlide>
                        )
                }
            </Swiper>
            <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable="true"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Please select a date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row xs={1} md={2} lg={2}>
                            <Col xs={6} md={4} lg={4} className='mb-2'>
                                <Image id={id} src={imgfreshcut} width="150px" height="150px" rounded />
                            </Col>
                            <Col xs={6} md={8} lg={8} style={{ fontFamily: 'monospace', fontSize: '0.8rem' }}>
                                <b>{name}</b>
                            </Col>
                        </Row>
                        <Row xs={1} md={1} lg={1} style={{ fontFamily: 'monospace' }}>
                            <b>Description :</b>
                            <p>
                                {description}
                            </p>
                            <div>
                                <b>Price : </b><i>{price}{device}</i>
                            </div>
                        </Row>
                        <div className='py-3'>
                            <div className='team-area'>
                                <TeamsInModal service={id} />
                            </div>
                            <div className='mb-5' id='contentCalendar' style={{ display: 'none' }}>
                                <Calender id_hairstyle={id}></Calender>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer hidden={hidden}>
                    <Button variant="secondary" onClick={handleHidden}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

        </div >

    )
    function chooseCut(id, url, price, desc, device, name) {
        setShow(true);
        setImgfreshcut(url);
        setId(id);
        setPrice(price);
        setDescription(desc);
        setDevice(device);
        setName(name);
    }
}

export default Freshcut