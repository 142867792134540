import React, { useEffect, useState } from 'react';
import { Alert, Breadcrumb, Button, Card, Col, Container, Form, ProgressBar, Row, Spinner } from 'react-bootstrap';
import { url } from '../data/functions';

import Iconbutton from '../components/Iconbutton';
import { indexnav, store_data, stripe_pk } from '../data/functions';
import Contact from '../components/Contact';
import { useNavigate } from 'react-router-dom';
import FormBusinessInfo from '../components/FormBusinessInfo';
import FormAddressInfo from '../components/FormAddressInfo';
import FormUserInfo from '../components/FormUserInfo';
import CheckoutForm from '../components/CheckoutForm';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(stripe_pk);

const CreateBusiness = (props) => {
    const [now, setNow] = useState(100 / 4);
    const navigate = useNavigate();
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        var cnt = document.getElementById("business-created");
        var list = cnt.querySelectorAll(".section-create-business");
        list[0].classList.add('active')
        indexnav['i'] = 0;

        var nv = document.getElementById("nav-business-created");
        var prev = nv.querySelectorAll(".prev");
        prev[0].classList.add('disabled')
        var next = nv.querySelectorAll(".next");
        next[0].classList.add('disabled')

        ////////// Checkout Stripe ///////////
        // Create PaymentIntent as soon as the page loads
        fetch(url + "/api/create-payment-intent/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ items: [{ abonnement: store_data['abonnement'] }, { amount: store_data['price'] }] }),
        })
            .then((res) => res.json())
            .then((data) => {
                setClientSecret(data.clientSecret)
                store_data['id_payment'] = data.id_payment
            });
    }, [])

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };


    const businessnavigation = (param) => {
        var elmt = document.querySelectorAll('.section-create-business');

        var nv = document.getElementById("nav-business-created");
        var prev = nv.querySelectorAll(".prev");
        var next = nv.querySelectorAll(".next");
        var i = indexnav['i'];

        switch (param) {
            case 'next':
                if (i >= elmt.length - 2) {
                    next[0].classList.add('disabled')
                }
                setNow(now + (100 / elmt.length))
                prev[0].classList.remove('disabled')
                next[0].classList.add('disabled')
                elmt[i].classList.remove('active')
                i++;
                elmt[i].classList.add('active')
                indexnav['i'] = i;
                break;
            case 'prev':
                if (i <= 1) {
                    prev[0].classList.add('disabled')
                };
                setNow(now - (100 / elmt.length))
                next[0].classList.remove('disabled')
                elmt[i].classList.remove('active')
                i--;
                elmt[i].classList.add('active')
                indexnav['i'] = i;
                break;
            default:
                break;
        }
        return elmt;
    };
    // console.log(errors)
    return (
        <div>
            <Container style={{
                maxWidth: '950px'
            }} id='scrollup'>
                <br />
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>{props.title}</Breadcrumb.Item>
                </Breadcrumb>
                <br />
                <ProgressBar now={now} label={`${now}%`} style={{ position: 'sticky', top: '0' }} />
                <div id="business-created">
                    <div class='section-create-business'>
                        <h4 className="my-4">Create your account</h4>
                        <FormUserInfo></FormUserInfo>
                    </div>
                    <div class='section-create-business'>
                        <h4 className="my-4">Business information</h4>
                        <FormBusinessInfo></FormBusinessInfo>
                    </div>
                    <div class='section-create-business'>
                        <h4 className="my-4">Address information</h4>
                        <FormAddressInfo></FormAddressInfo>
                    </div>
                    <div class='section-create-business payment'>
                        <h4 className="my-4">Payment</h4>
                        {/* <FormCardInfo></FormCardInfo> */}

                        {clientSecret && (
                            <Elements options={options} stripe={stripePromise}>
                                <CheckoutForm />
                            </Elements>
                        )}
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    padding: '5px',
                    backgroundColor: '#eaecef'
                }} className='my-5' id='nav-business-created'>
                    <a href='#' className='prev btn btn-primary' onClick={() => businessnavigation('prev')}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg> Prev</a>
                    <a href='#' className='next btn btn-primary' onClick={() => businessnavigation('next')}>Next <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                    </svg></a>
                </div>
                <br /><br />
                <Iconbutton></Iconbutton>
                <br />
            </Container>
            {/* <div className="section-contact" id="section-contact">
                <div className="container">
                    <Contact></Contact>
                </div>
            </div> */}
        </div>

    );
};


export default CreateBusiness;