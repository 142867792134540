import React from 'react';
import { Breadcrumb, Card, Col, Row } from 'react-bootstrap';
import { store_data } from '../data/functions';
import { useNavigate } from 'react-router-dom';

const Pricing = (props) => {
    const navigate = useNavigate();
    return (
        <div className='container'>
            <br />
            <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>{props.title}</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <div class="pricing-header p-3 pb-md-4 mx-auto text-center">
                <h1 class="display-4 fw-normal text-body-emphasis">Pricing</h1>
                <p class="fs-5 text-body-secondary">Choose the plan that suits you best</p>
            </div>
            <Row xs={1} md={2} lg={3} className='mb-3 text-center' style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Col>
                    <Card className='mb-4 rounded-3 shadow-sm border-primary'>
                        <Card.Header className='py-3 text-bg-primary border-primary'>
                            <h4 class="my-0 fw-normal">Monthly</h4>
                        </Card.Header>
                        <Card.Body>
                            <h1 class="card-title pricing-card-title">$25(HT)<small class="text-body-secondary fw-light">/month</small></h1>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li>Ideal for discovering</li>
                                {/* <li>CA$ 28,75 (TTC)</li> */}
                                {/* <li>Phone and email support</li> */}
                                <li>Phone and email support</li>
                                <li>&nbsp;</li>
                            </ul>
                            <a href="#" onClick={() => {
                                store_data['abonnement'] = 'Monthly'
                                store_data['price'] = '25'
                                sessionStorage.setItem('ab', 'mo')
                                navigate('/create-my-business')
                            }} className='w-100 btn btn-lg btn-primary'>Get started</a>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className='mb-4 rounded-3 shadow-sm border-primary'>
                        <Card.Header className='py-3 text-bg-primary border-primary'>
                            <h4 class="my-0 fw-normal">Annual</h4>
                        </Card.Header>
                        <Card.Body>
                            <h1 class="card-title pricing-card-title">$250(HT)<small class="text-body-secondary fw-light">/year</small></h1>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li>The most popular</li>
                                <li>Save CA$ 50</li>
                                {/* <li>CA$ 287,50 (TTC)</li> */}
                                <li>Phone and email support</li>
                            </ul>
                            <a href="#" onClick={() => {
                                store_data['abonnement'] = 'Annual'
                                store_data['price'] = '250'
                                sessionStorage.setItem('ab', 'an')
                                navigate('/create-my-business')
                            }} className='w-100 btn btn-lg btn-primary'>Get started</a>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Pricing;