import React, { useEffect, useState } from 'react';
import { client, getCookie } from '../data/functions';

// import Swiper core and required modules
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Import Skeleton
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Galeries = () => {
    const [galeries, SetGaleries] = useState([]);
    const [isloading, SetIsloading] = useState(false);
    const numGallerie = [0, 0, 0, 0, 0, 0, 0, 0, 0];

    useEffect(() => {
        SetIsloading(true)
        const store = sessionStorage.getItem('st');
        client.get('api/store/' + store + '/galerie/',
            { withCredentials: true },
            {
                headers: { "X-CSRFToken": getCookie('csrftoken') },
            }
        ).then((res) => {
            SetIsloading(false)
            SetGaleries(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])
    return (
        <Swiper
            style={{
                '--swiper-pagination-color': '#fff',
            }}
            pagination={{
                clickable: true,
            }}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            loop={true}
            effect={'coverflow'}
            slidesPerView={'auto'}
            centeredSlides={true}
            grabCursor={true}
            coverflowEffect={{
                rotate: 10,
                stretch: 2,
                depth: 50,
                modifier: 2.5,
                slideShadows: true,
            }}
            // install Swiper modules
            modules={[Autoplay, EffectCoverflow, Pagination]}
        // className='mySwiper-2'
        >
            {
                (isloading) ?
                    numGallerie.map((gallerie, i) =>
                        <SwiperSlide key={i}>
                            <div className='item'>
                                <Skeleton
                                    width={300}
                                    height={350}
                                    baseColor="#000000"
                                    highlightColor="#cbbd87"
                                    duration={3}
                                    borderRadius="32px"
                                />
                            </div>
                        </SwiperSlide>
                    ) :
                    galeries.map((galerie, i) =>
                        <SwiperSlide key={i}>
                            <img src={galerie.image} width={'300px'} height={'350px'} alt='flexiserve_galerie_img' />
                        </SwiperSlide>
                    )}
        </Swiper>
    );
};

export default Galeries;