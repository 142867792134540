import React, { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { store_data, stripe_pk, url } from '../data/functions';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(stripe_pk);

const RenewAction = () => {
    const [clientSecret, setClientSecret] = useState("");
    const [isLoading, setLoading] = useState(false);

    const getPaymentIntent = () => {
        setLoading(true)
        ////////// Checkout Stripe ///////////
        // Create PaymentIntent as soon as the page loads
        fetch(url + "/api/create-payment-intent/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ items: [{ abonnement: store_data['abonnement'] }, { amount: store_data['price'] }] }),
        })
            .then((res) => res.json())
            .then((data) => {
                setClientSecret(data.clientSecret)
                store_data['id_payment'] = data.id_payment
                setLoading(false)
            });
    }
    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };
    return (
        <div style={{ fontFamily: 'serif' }}>
            <Row xs={1} md={2} lg={2} className='mb-3 text-center'>
                <Col>
                    <Card className='mb-4 rounded-3 shadow-sm border-primary'>
                        <Card.Header className='py-3 text-bg-primary border-primary'>
                            <h4 class="my-0 fw-normal">Monthly</h4>
                        </Card.Header>
                        <Card.Body>
                            <h1 class="card-title pricing-card-title">$25<small class="text-body-secondary fw-light">/month</small></h1>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li>Ideal for discovering</li>
                                <li>CA$ 28,75 (TTC)</li>
                                <li>Phone and email support</li>
                                <li>&nbsp;</li>
                            </ul>
                            <Button onClick={() => {
                                store_data['abonnement'] = 'Monthly'
                                store_data['price'] = '25'
                                getPaymentIntent()
                            }} className='w-100 btn btn-lg btn-primary'
                            >
                                {
                                    isLoading ? 'Loading...' : 'Get started'
                                }
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className='mb-4 rounded-3 shadow-sm border-primary'>
                        <Card.Header className='py-3 text-bg-primary border-primary'>
                            <h4 class="my-0 fw-normal">Annual</h4>
                        </Card.Header>
                        <Card.Body>
                            <h1 class="card-title pricing-card-title">$250<small class="text-body-secondary fw-light">/year</small></h1>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li>The most popular</li>
                                <li>Save CA$ 50</li>
                                <li>CA$ 287,50 (TTC)</li>
                                <li>Phone and email support</li>
                            </ul>
                            <Button onClick={() => {
                                store_data['abonnement'] = 'Annual'
                                store_data['price'] = '250'
                                getPaymentIntent()
                            }} className='w-100 btn btn-lg btn-primary'
                            >
                                {
                                    isLoading ? 'Loading...' : 'Get started'
                                }
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row xs={1} md={1} lg={1} className='mb-3'>
                <Col>
                    {clientSecret && (
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm />
                        </Elements>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default RenewAction;