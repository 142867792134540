import React from 'react';
import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap';
import Teams from './Teams';

const Businesshour = () => {
    return (
        <div className="containt-businesshour">
            <Row xs={1} md={1}>
                <Col lg={12} className='containt-businesshour direction-left'>
                    {/* <Col> */}
                    <Row xs={12} md={6}>
                        <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                            <h4 style={{ color: 'yellow' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                                </svg>
                                &nbsp;Important guidelines&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                                </svg></h4>
                        </Col>
                        <Col xs={12} md={6}>

                            <Row xs={2} md={2} style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                                <Col xs={12} md={12}>
                                    <ul>
                                        <li>Create a user account</li>
                                        <li>Select the desired service</li>
                                        <li>Specify your preferences</li>
                                        <li>Check team member availability</li>
                                        <li>Inform in case of delay or cancellation</li>
                                        <li>Update your contact information</li>
                                    </ul>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={12} md={6}>
                            <Row xs={2} md={2} style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                                <Col xs={12} md={12}>
                                    <ul>
                                        <li>Follow sanitary measures</li>
                                        <li>Prepare your payment method</li>
                                        <li>Leave a review</li>
                                        <li>Confirm the booking</li>
                                        <li>Arrive on time</li>
                                        <li>Check for special offers</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* </Col> */}
                </Col>
                <Col xs={12} md={12} className='containt-team direction-left'>
                    <Teams></Teams>
                </Col>
            </Row>
        </div>
    )
};

export default Businesshour;