// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../img/IMG_ES_08.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sucess-page {
  --bg-image-opacity: .80;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  position: relative;
  isolation: isolate;
  color: white;
}
@media screen and (max-width: 450px) {
  .sucess-page {
    background-position-x: -500px;
  }
}

.sucess-page::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: #302000;
  z-index: -1;
  opacity: var(--bg-image-opacity);
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/_sucesspage.css"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,yDAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;AACJ;AACI;EARJ;IASQ,6BAAA;EAEN;AACF;;AACA;EACI,WAAA;EACA,kBAAA;EACA,QAAA;EACA,yBAAA;EACA,WAAA;EACA,gCAAA;AAEJ","sourcesContent":[".sucess-page {\n    --bg-image-opacity: .80;\n    background-image: url('/img/IMG_ES_08.jpg');\n    background-size: cover;\n    position: relative;\n    isolation: isolate;\n    color: white;\n\n    @media screen and (max-width: 450px) {\n        background-position-x: -500px;\n    }\n}\n\n.sucess-page::before {\n    content: \"\";\n    position: absolute;\n    inset: 0;\n    background-color: #302000;\n    z-index: -1;\n    opacity: var(--bg-image-opacity);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
