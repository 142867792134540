// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .fc .fc-bg-event {
    opacity: unset;
    background-color: var(--fc-button-bg-color);
}

.fc .fc-bg-event .fc-event-title {
    color: white;
} */
.bg-events-custom {
  opacity: unset;
  background-color: var(--fc-button-bg-color);
}

.fc .fc-toolbar-title {
  font-size: 1.05em;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/_calendar.css"],"names":[],"mappings":"AAAA;;;;;;;GAAA;AAQA;EACI,cAAA;EACA,2CAAA;AACJ;;AAEA;EACI,iBAAA;AACJ","sourcesContent":["/* .fc .fc-bg-event {\n    opacity: unset;\n    background-color: var(--fc-button-bg-color);\n}\n\n.fc .fc-bg-event .fc-event-title {\n    color: white;\n} */\n.bg-events-custom {\n    opacity: unset;\n    background-color: var(--fc-button-bg-color);\n}\n\n.fc .fc-toolbar-title {\n    font-size: 1.05em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
