// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-believe-text {
  width: 50%;
  padding: 15px;
  margin: 15px 0 15px 0;
  border-left: 5px solid #caa405;
  font-family: monospace, monospace;
}
@media screen and (max-width: 450px) {
  .content-believe-text {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/components/_believe.css"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,aAAA;EACA,qBAAA;EACA,8BAAA;EACA,iCAAA;AACJ;AACI;EAPJ;IAQQ,WAAA;EAEN;AACF","sourcesContent":[".content-believe-text {\n    width: 50%;\n    padding: 15px;\n    margin: 15px 0 15px 0;\n    border-left: 5px solid #caa405;\n    font-family: monospace, monospace;\n\n    @media screen and (max-width: 450px) {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
