import React, { useState } from 'react';
import { client, getCookie, store_data } from '../data/functions';
import { Alert, Button, Form } from 'react-bootstrap';
import emailjs from 'emailjs-com';

let certifyStatus = ''

const Certify = () => {
    const [disableButton, setDisableButton] = useState(false);

    switch (store_data.certify_status) {
        case '0':
            certifyStatus = 'Not certified'
            break;
        case '1':
            certifyStatus = 'In progress'
            break;
        case '2':
            certifyStatus = 'Certified'
            break;
        default:
            break;
    }

    const sendDoc = () => {
        document.getElementById('loading_certif').innerHTML = '<div class="text-center"><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>';
        document.getElementById('loading_certif').style.display = 'block';
        setDisableButton(true)
        const formData = new FormData()
        var docFile = document.getElementById('docFile').files[0]
        formData.append('docFile', docFile)
        formData.append('st', sessionStorage.getItem('st'))
        client.post('api/store/certification/', formData,
            { withCredentials: true },
            {
                headers: { "X-CSRFToken": getCookie('csrftoken') },
            }
        ).then((res) => {
            document.getElementById('loading_certif').innerHTML = '<div class="alert alert-success capitalize-hp" role="alert">Your document has been sent</div>';
            store_data.certify_status = '1'
            /////Envoie de email confirmation////////
            var templateParams = {
                store_name: sessionStorage.getItem('st_name')
            };
            //Send email notification
            emailjs.send('service_d8ms69q', 'template_0v1ggzs', templateParams, 'YQsWN-aRt2cYU1Fc7')
                .then((res) => {
                    // console.log(res)
                }, (error) => {
                    console.log(error.text);
                });
            return true
        }).catch((err) => {
            console.log(err);
            return false
        })
    }

    return (
        <div style={{ fontFamily: 'serif' }}>
            <p>Your account its : <b>{certifyStatus}</b></p>
            {
                (store_data.certify_status == '0') ? <Alert key='warning' variant='warning'>
                    <p>Submit your valid government-issued ID that has not been revoked so we can certify your account.<br />
                        Send one of these:
                        <ul>
                            <li>Driver licence</li>
                            <li>Passport</li>
                            <li>Professional licence</li>
                        </ul>
                        Examination of your document will take 3 business days. <br />
                        Please rename your document as : [name_of_store]_[type_of_document(Licence/Passport)]
                    </p>
                    <p>Submit double-sided copies in a single document.</p>
                </Alert> : (store_data.certify_status == '1') ? <Alert key='warning' variant='warning'>
                    <p>Your document has been sent and is currently being reviewed..</p>
                    <p>Examination of your document will take 3 business days.</p>
                </Alert> : ''
            }
            {
                (store_data.certify_status == '0') ?
                    <div>
                        <div class="input-group mb-3">
                            <input type="file" class="form-control" id="docFile" />
                        </div>
                        <div id="loading_certif" style={{ margin: '5px 0 5px', display: 'none', textAlign: 'center' }}></div>
                        <Button onClick={sendDoc} disabled={disableButton}>Send Document</Button>
                    </div>
                    : ''
            }

        </div>
    );
};

export default Certify;