import React from 'react';
import Mynavbar from '../components/Navbarcomp';
import Headerpage from '../components/Headerpage';
import Footer from '../components/Footer';
import Contact from '../components/Contact';
import { Breadcrumb } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Iconbutton from '../components/Iconbutton';

const About = (props) => {
    return (
        <div id='scrollup'>
            <Mynavbar></Mynavbar>
            <Headerpage title={props.title}></Headerpage>
            <div className="container" style={{
                marginBottom: '100px'
            }}>
                <br />
                <Breadcrumb>
                    <Breadcrumb.Item href={props.storeid}>Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>{props.title}</Breadcrumb.Item>
                </Breadcrumb>
                <br />
                <Accordion defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Our values</Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <li>
                                    <b>Personalized approach :</b>
                                    <p>At <i><b>{sessionStorage.getItem('st_name')}</b></i>, We are committed to providing tailored solutions that take into account each individual’s unique needs, ensuring a customized experience that reflects who you are.</p>
                                </li>
                                <li>
                                    <b>Expertise and professionalism :</b>
                                    <p>Our team consists of qualified and passionate professionals, delivering high-quality service no matter the request.</p>
                                </li>
                                <li>
                                    <b>Ongoing support :</b>
                                    <p>At <i><b>{sessionStorage.getItem('st_name')}</b></i>, we provide attentive follow-up and continuous support, guiding you through every step to ensure optimal results and lasting satisfaction.</p>
                                </li>
                                <li>
                                    <b>Respect and ethics :</b>
                                    <p>We prioritize a caring and respectful approach, where every interaction is handled with integrity and attention, ensuring our services align with your values.</p>
                                </li>
                                <li>
                                    <b>Trust and security :</b>
                                    <p>We place great importance on protecting your personal information and providing a trusted environment where you can express yourself and grow with confidence.</p>
                                </li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Our missions</Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <li>
                                    <b>Empowering individuals :</b>
                                    <p>Our mission is to empower individuals by providing services that enhance their personal growth and well-being, allowing everyone to thrive in their unique journey.</p>
                                </li>
                                <li>
                                    <b>Delivering excellence :</b>
                                    <p>We strive to deliver high-quality, reliable services that meet the needs and expectations of our users, ensuring a positive and impactful experience every time.</p>
                                </li>
                                <li>
                                    <b>Promoting well-being :</b>
                                    <p>We aim to improve the well-being of our community by offering solutions that promote balance, confidence, and a healthier lifestyle, no matter the need.</p>
                                </li>
                                <li>
                                    <b>Fostering trust and transparency :</b>
                                    <p>We are committed to building a relationship of trust with our users by maintaining transparency in our processes and ensuring a safe, secure environment for all.</p>
                                </li>
                                <li>
                                    <b>Continuous innovation :</b>
                                    <p>Our mission is to continually innovate and evolve, leveraging the latest advancements to provide modern, efficient, and accessible services to everyone.</p>
                                </li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="section-contact" id="section-contact">
                <div className="container">
                    <Contact></Contact>
                </div>
            </div>
            <Iconbutton></Iconbutton>
            <Footer></Footer>
        </div>
    );
};

export default About;